<template>
  <div class="followup-wrapper">
    <PageHeaderCount
      moduleType="customer"
      :dataLoading="false"
      :statusList="statusList"
      allkey="all_FollowUps"
      countkey="follow_up_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'followup-listing'">
      <template v-slot:body>
        <div class="tabledata-wrapper">
          <div id="list-panel">
            <div class="list-panel-heading">
              <h1 class="panel-title"><i class="fa fa-list"></i> Follow up</h1>
            </div>
            <div class="detail-inner">
              <v-tabs
                v-model="followupTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent"
              >
                <v-tab class="font-size font-weight-600 px-8" href="#all">
                  All
                </v-tab>
                <v-tab class="font-size font-weight-600 px-8" href="#open">
                  Open
                </v-tab>
                <v-tab class="font-size font-weight-600 px-8" href="#closed">
                  Closed
                </v-tab>
              </v-tabs>
            </div>
            <div class="table">
              <v-tabs-items v-model="followupTab">
                <v-tab-item value="all">
                  <Tabledata
                    v-on:statusList="updateStatusList"
                    type="all"
                  ></Tabledata>
                </v-tab-item>
                <v-tab-item value="open">
                  <Tabledata
                    v-on:statusList="updateStatusList"
                    type="open"
                  ></Tabledata>
                </v-tab-item>
                <v-tab-item value="closed">
                  <Tabledata
                    v-on:statusList="updateStatusList"
                    type="closed"
                  ></Tabledata>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Customer</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Customer')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <!-- table dialog -->
      </template>
    </ListingTemplate>
  </div>
</template>
<script>
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Tabledata from "@/view/pages/follow-up/Tabledata.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";

export default {
  name: "Follow-ups",
  mixins: [CommonMixin],
  data() {
    return {
      //tab:null,
      //items: [10, 25, 50, 75, 100, ],
      routeAPI: "followup",
      routeName: "followup",
      routeDetailName: "followup.detail",
      status: "all",
      pageModule: "followup-listing",
      dataLoading: false,
      searchDialog: false,
      searchDialogtable: false,
      statusList: [
        {
          id: 5,
          type: 1,
          text: "All Follow-Up",
          value: "all",
          description: "All Follow-Up",
          activity_text: "All Follow-Up",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: 1,
          status: 0,
          top_visible: true,
          activated: true,
          added_by: 1,
          added_at: "2022-05-10 08:32:37",
          updated_by: 0,
          updated_at: null,
          customer_status_count: 0,
          all_customers: 0,
        },
        {
          id: 5,
          type: 1,
          text: "Open",
          value: "all",
          description: "Open",
          activity_text: "Open",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: 1,
          status: 0,
          top_visible: true,
          activated: true,
          added_by: 1,
          added_at: "2022-05-10 08:32:37",
          updated_by: 0,
          updated_at: null,
          customer_status_count: 0,
          all_customers: 0,
        },
        {
          id: 5,
          type: 1,
          text: "Closed",
          value: "all",
          description: "Closed",
          activity_text: "Closed",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: 1,
          status: 0,
          top_visible: true,
          activated: true,
          added_by: 1,
          added_at: "2022-05-10 08:32:37",
          updated_by: 0,
          updated_at: null,
          customer_status_count: 0,
          all_customers: 0,
        },
      ],
    };
  },
  components: {
    PageHeaderCount,
    ListingTemplate,
    // ListingSearchCriteria,
    Tabledata,
    Dialog,
  },
  created() {
    const _this = this;
    if (_this.$route.query && _this.$route.query.tab) {
      _this.followupTab = _this.$route.query.tab;
    }
  },
  methods: {
    updateStatusList(param) {
      this.statusList = param;
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    getBillingAddress(billing) {
      let billingArray = new Array();
      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }
        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }
        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }
        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }
      return billingArray.join(", ");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkCustomerExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("customer");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
  },
  computed: {
    followupTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.followupTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
  },
};
</script>
